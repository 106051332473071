export default defineAppConfig({
  saasName: 'Aszendit',
  saasLink: 'https://aszendit.fsbd.ai',
  customSaas: true,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si el briefing se pone en "oculto" únicamente lo visualizarán los usuarios admin, y en "visible" todos los usuarios de cualquier departamento.'
    }
  },
  analyticsLooker: 'd25f63c2-5be8-4c07-95ac-35eb77fea168',
  adify: {
    actions: ['Aumentar', 'Reducir', 'Pausar', 'Mantener', 'Revisar'],
    platforms: ['Google Ads', 'Facebook Ads'],
    vertical: false,
    country: false
  },
  savvywords: {
    customers: [
      {
        name: 'Viuty',
        code: 'aszendit-viuty',
        savvywordsDataset: 'VIUTY_SAVVYWORDS',
        googleadsDataset: 'VIUTY_GOOGLE_ADS',
        searchtermsBucket: 'keywords_analysis_viuty'
      }
    ]
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      },
      {
        name: 'Viuty ES (330221872266946)',
        act: 330221872266946
      },
      {
        name: 'Viuty DE (317808430291919)',
        act: 317808430291919
      },
      {
        name: 'Viuty IT (619690274153130)',
        act: 619690274153130
      },
      {
        name: 'Viuty PT (479878871723019)',
        act: 479878871723019
      },
      /*{
        name: 'Viuty TEMP (2720724804637535)',
        act: 2720724804637535
      },
      {
        name: 'Matcha Jeans (518151730094875)',
        act: 518151730094875
      },*/
    ]
  }
})